import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { getUserData } from '@/auth/utils'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useTituloList(credorId) {
  // Use toast
  const toast = useToast()
  
  const userData = getUserData()

  const refTituloListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // '#',
    { key: 'nome', label: "Devedor" },
    { key: 'numero_titulo' },
    { key: 'valor' },
    { key: 'vencimento' },
    { key: 'referencia' },
    { key: 'situacao' },
    { key: 'credor', label: "Credor" },
    { key: 'actions', label: "Ações" },
  ]
  const perPage = ref(15)
  const currentPage = ref(1)
  const perPageOptions = [10, 15, 25, 50, 100]
  const searchQuery = ref('')
  const situacaoFilter = ref(null)

  const dataMeta = ref({})

  const refetchData = () => {
    refTituloListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, situacaoFilter], () => {
    refetchData()
  })

  const fetchTitulos = (ctx, callback) => {
    store
      .dispatch('app-titulo/fetchTitulos', {
        busca: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        situcredor: situacaoFilter.value,
        credor_id: userData.credor.id
      })
      .then(response => {
        const { data, meta } = response.data     
        callback(data)
        dataMeta.value = meta
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveTituloStatusVariant = status => {
    if (status === 'NEGATIVADO') return 'primary'
    if (status === 'BAIXADO') return 'success'
    if (status === 'PRESCRITO') return 'primary'
    if (status === 'CADASTRADO') return 'info'
    return 'primary'
  }

  const resolveTituloStatusText = text => {
    if (text === 'NEGATIVADO') return 'ABERTO'
    if (text === 'BAIXADO') return 'BAIXADO'
    if (text === 'PRESCRITO') return 'ABERTO'
    if (text === 'CADASTRADO') return 'ABERTO'
    return 'primary'
  }

  return {
    fetchTitulos,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    refTituloListTable,

    resolveTituloStatusVariant,
    resolveTituloStatusText,
    refetchData,

    // Extra Filters
    situacaoFilter,
  }
}
